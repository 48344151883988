import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { expand, Observable, of, scan, switchMap, takeWhile, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LoginResponse } from '../models/login-response.model';
import { UserPostParam } from '../models/user-post-param.model';
import { User, UserPostResponse } from '../models/user.model';
import { NavigationStateService } from './navigation-state.service';
import { ProjectService } from './project.service';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  readonly #URL = `${environment.apiUrl}/users`;
  readonly #http = inject(HttpClient);

  usersSignal = signal<User[]>([]);
  getAllUsers(
    type: 'admins' | 'skippers' | 'users' = 'users',
    search = '',
  ): Observable<any[]> {
    const fetchPage = (page: number): Observable<any> => {
      let requestUrl = `${this.#URL}/list/${type}?page=${page}&perPage=1000`;

      if (search !== '') {
        requestUrl += `&search=${search}`;
      }

      return this.#http
        .get<any>(requestUrl)
        .pipe(tap((response) => this.usersSignal.set([...response.data.data])));
    };

    return fetchPage(1).pipe(
      expand((response) => {
        if (response.data.next_page_url) {
          const nextPage = response.data.current_page + 1;
          return fetchPage(nextPage);
        }
        return of(null);
      }),
      takeWhile((response) => response !== null),
      scan((allUsers: any[], response) => {
        const newUser = response?.data.data ?? [];
        return allUsers.concat(newUser);
      }, []),
    );
  }

  tokenService = inject(TokenService);
  #projectService = inject(ProjectService);
  #navigationStateService = inject(NavigationStateService);
  logAs(id: number) {
    return this.#http.get<LoginResponse>(`${this.#URL}/logAs/${id}`).pipe(
      switchMap((user) => {
        this.tokenService.setDelegatedToken(user);

        return this.#projectService.getProjects().pipe(
          tap((projects) => {
            let projectId: number;
            if (user.user?.favorite_project) {
              projectId = user.user.favorite_project;
            } else {
              projectId = projects[0]?.clients[0]?.projects[0]?.id;
            }
            this.#navigationStateService.setProjectId(projectId!);
          }),
        );
      }),
    );
  }

  delete(id: number) {
    return this.#http.delete(`${this.#URL}/${id}`);
  }

  create(user: UserPostParam, type: 'admins' | 'skippers' | 'users' = 'users') {
    user.active = 1;
    return this.#http.post<UserPostResponse>(`${this.#URL}/${type}`, user);
  }

  update(user: UserPostParam) {
    user.active = 1;
    return this.#http.put<UserPostResponse>(`${this.#URL}/${user.id}`, user);
  }

  myData() {
    return this.#http.get<User>(`${environment.apiUrl}/me`);
  }

  importFile(body: any) {
    return this.#http.post(`${environment.apiUrl}/users/import`, body);
  }

  regeneratePassword(id: number) {
    return this.#http.get(`${this.#URL}/resetPassword/${id}`);
  }

  generateStrongPassword(): string {
    const length = 10;
    const numbers = '0123456789';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const specialChars = '!@#$%^&*(),.?":{}|<>';

    const allChars = lowercase + uppercase + numbers + specialChars;

    let password = '';

    password += numbers[Math.floor(Math.random() * numbers.length)];
    password += uppercase[Math.floor(Math.random() * uppercase.length)];
    password += lowercase[Math.floor(Math.random() * lowercase.length)];
    password += specialChars[Math.floor(Math.random() * specialChars.length)];

    for (let i = password.length; i < length; i++) {
      password += allChars[Math.floor(Math.random() * allChars.length)];
    }

    return password
      .split('')
      .sort(() => 0.5 - Math.random())
      .join('');
  }
}
